import React from "react";
import PropTypes from "prop-types";

class UserFilterDropdown extends React.Component {
  render() {
    const { options, onChange, selected } = this.props;

    if (options.length === 0) return null;

    return (
      <select
        id={"user-filters"}
        onChange={onChange}
        value={selected}
        className={selected !== "" ? "active" : ""}
        style={{ minWidth: 100, maxWidth: 150 }}
      >
        <option key="" value="" />
        {options.map(filter => {
          return (
            <option
              key={`${filter.id}-${filter.name}`}
              value={filter.filterModel}
            >
              {filter.name}
            </option>
          );
        })}
      </select>
    );
  }
}

UserFilterDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      filterModel: PropTypes.string.isRequired
    })
  ).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default UserFilterDropdown;
