import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import { OrderObject, CurrentUserObject } from "./Queries/OrderQuery";

import orderFragments from "./Queries/graphql/orderFragments";
import orderLinesAndEvents from "./Queries/graphql/orderLinesAndEvents";
import commentFragments from "./Queries/graphql/commentFragments";
import orderAllocationsFragments from "./Queries/graphql/orderAllocationsFragments";
import AddItems from "./Lines/AddItems";

const ORDER_UPDATER = gql`
  mutation updateOrder($id: ID!, $input: OrderInput!) {
    updateOrder(id: $id, input: $input) {
      order {
        id
        ...OrderFields
        ...OrderLinesAndEventsQuery
        partner {
          id
          name
        }
        installPartner {
          id
          name
        }
        comments {
          ...CommentQuery
        }
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${commentFragments.query}
  ${orderAllocationsFragments.query}
`;

class OrderUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render, currentUser } = this.props;
    return (
      <Mutation
        mutation={ORDER_UPDATER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const updateOrder = order => {
            const input = {};
            const updateProperty = propName => {
              if (order.hasOwnProperty(propName)) {
                input[propName] = order[propName];
              }
            };

            // Whitelist what we pass across
            updateProperty("clinicalJustificationProvided");
            updateProperty("clinicalJustificationNote");
            updateProperty("priorApprovalEffectedAt");
            updateProperty("status");
            updateProperty("seekPriorApproval");
            updateProperty("partnerRequestedApproval");
            updateProperty("priorApprovalNumber");
            updateProperty("inReassign");
            updateProperty("vic30DayDischarge");
            updateProperty("dateReceived");
            updateProperty("followUpOn");
            updateProperty("npFollowUpOn");
            updateProperty("costCentre");
            updateProperty("cpo");
            updateProperty("urgent");
            updateProperty("awaitingCall");
            updateProperty("salesRepId");
            updateProperty("debtorId");
            updateProperty("updateSource");
            updateProperty("consignment");
            updateProperty("complexHomeMods");
            updateProperty("adminNotes");
            updateProperty("trialDeliveryEnabled");
            updateProperty("partnerId");
            updateProperty("installPartnerId");
            update({
              variables: {
                id: order.id,
                input: input
              }
            });
          };
          return render({
            currentUser,
            order,
            updateOrder
          });
        }}
      </Mutation>
    );
  }

  onCompleted = data => {
    if (this.props.onComplete) {
      this.props.onComplete();
    }
  };

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

OrderUpdater.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject,
  onComplete: PropTypes.func,
  render: PropTypes.func
};

export default OrderUpdater;
